import { io } from 'socket.io-client'

/** @type {import('vue').PluginObject} */
export default {
  install(Vue) {
    /**
     * @type {import('socket.io-client').Socket}
     */
    let socket
    /** 
     * @type {string}
     */
    let socketId
    /** 
     * @type {import('vuex').Store}
     */
    let store

    const client = {
      /**
       * @type {string} Current Socket ID
       */
      id: socketId,
      /**
       * @param {string} channel
       * @param  {...any} args
       */
      emit: (channel, ...args) => socket.emit(channel, ...args),
      /**
       * @param {string} channel
       * @param  {Function} fn
       */
      on: (channel, fn) => socket.on(channel, fn),
      /**
       * @param {string} channel
       * @param  {Function} fn
       */
      off: (channel, fn) => socket.off(channel, fn),
      /**
       * @param {string} channel
       * @param  {...any} args
       */
      once: (channel, ...args) => socket.once(channel, ...args),
    }

    Vue.prototype.$socket = {
      /**
       * Initialize the socket connection
       * 
       * @param {import('vuex').Store} storeInstance
       */
      init: (storeInstance) => new Promise((resolve) => {
        store = storeInstance
        const backend = store.state.config.konecta

        socket = io(
          // Plain URL without the `/api` part
          backend.split('/').slice(0, 3).join('/'),
          {
            reconnection: true,
            transports: ['websocket', 'polling'],
            path: backend.endsWith("/api") ? '/api/socket.io' : undefined,
          }
        )

        socket.on('disconnect', () => {
          console.info('SOCKET_DISCONNECT')
        });

        socket.on('connect', () => {
          store.commit('UPDATE_SOCKET', { client })
          store.commit('SOCKET_CONNECT')
          client.id = socket.id
          resolve()
        });
      }),
      /**
       * Close the socket connection
       */
      close: () => new Promise((resolve) => {
        return socket.disconnect(() => {
          console.info('SOCKET_DISCONNECT')
          resolve()
        });
      }),
      client
    }
  }
}
