import moment from 'moment'

/** @type {import('vue').PluginObject} */
export default {
  install(Vue) {
    /**
     * Format a Date object
     * 
     * @param {Date} value 
     * @param {boolean=} fullDate 
     * @returns {string}
     */
    const date = (value, fullDate) => {
      if (Object.prototype.toString.call(value) !== "[object Date]") {
        // If value is not of type Date, we convert it to avoid Moment warnings
        value = new Date(value)
      }
      if (!fullDate) return moment(value).format('DD MMM')
      return moment(value).format('DD MMM YYYY')
    }

    Vue.prototype.$date = date
    Vue.prototype.$moment = moment
  }
}