/** @type {import('vue').PluginObject} */
export default {
  install(Vue) {
    /**
     * @see https://vitejs.dev/guide/assets.html#new-url-url-import-meta-url
     * 
     * @param {string} sourcePathOrName 
     * @returns {string}
     */
    Vue.prototype.$imageLoad = (sourcePathOrName) => {
      // Check to remove leading slash
      if (sourcePathOrName.startsWith('/')) {
        sourcePathOrName = sourcePathOrName.substring(1)
      }

      return new URL(`../assets/images/${sourcePathOrName}`, import.meta.url).href
    }
  }
}
